export default {
  seq: '序号',
  activate: '去开通',
  renew: '去续费',
  add: '添加店铺',
  editBrowser: '编辑店铺',
  import: '导入店铺',
  batchAdd: '批量添加店铺',
  batchUpdate: '批量更新店铺',
  used: '已用',
  total: '总数',
  pkgBrowsers: '套餐店铺数：',
  pkgUsed: '已创建店铺数：',
  pkgAssigned: '已授权店铺数：',
  shareUser: '已授权给员工：',
  openCount: '今日打开',
  activatePkg: '开通套餐',
  changePkg: '变更套餐',
  placeholder1: '选择员工账号搜索',
  all: '全部',
  mine: '自建',
  commonly: '常用店铺',
  allStatus: '全部',
  opened: '已打开',
  unOpened: '未打开',
  share2me: '分享',
  transform2me: '转移',
  group: '分组',
  groups: '查看分组',
  noGroup: '未分组',
  checked: '已选：',
  checkedDevice: '已选设备：',
  checkedRole: '已选角色：',
  refreshData: '刷新数据',
  customizeColumns: '自定义显示列',
  recycle: '回收站',
  moreAction: '批量操作',
  openChecked: '打开选中店铺',
  clone: '克隆选中店铺',
  clone1: '克隆店铺',
  setCommonly: '将选中设为常用店铺',
  setCommonly1: '设置常用店铺',
  cancelCommonly: '将选中店铺取消常用店铺',
  cancelCommonly1: '取消常用店铺',
  export: '批量导出店铺',
  reopenAtPos: '批量重启店铺',
  reopenSelectedAtPos: '批量重启选中店铺',
  reopenAllAtPos: '批量重启所有店铺',
  exportChecked: '批量更新店铺数据',
  exportBySeq: '导出指定序号范围店铺',
  exportByGroup: '导出指定分组店铺',
  batchEdit: '批量修改店铺',
  batchEditRemark: '批量修改店铺备注',
  updateGroupOfChecked: '修改选中店铺分组',
  updateProxyOfChecked: '修改选中店铺代理',
  updateRemarkOfChecked: '修改选中店铺备注',
  updatePropertiesOfChecked: '修改选中店铺所有配置',
  closeBrowsers: '批量关闭店铺',
  closeBrowser: '关闭店铺',
  openBrowser: '打开店铺',
  closeChecked: '批量关闭选中店铺',
  closeAll: '批量关闭所有店铺',
  persistenceCookie: '非持久化Cookie设置',
  persistenceCookieTip: '是否设置非持久化Cookie的有效时间？',
  persistenceCookieTip1: '非持久化Cookie有效时间设置：',
  persistenceCookieTip2: '请输入有效的时间值',
  persistenceCookieTip3: '1.非持久化Cookie，是指当浏览器窗口关闭后会被立刻清除的Cookie文件，如：虾皮和速卖通...',
  persistenceCookieTip4: '2.非持久化Cookie设置，主要为了帮助用户实现在一定时间范围内保存临时性的Cookie，从而减少用户反复登录的操作。',
  persistenceCookieTip5: '3.点击“是”以后，可设置保存非持久化Cookie的有效时间。设置完成后，会延长非持久化Cookie的保存时间！',
  customTime: '自定义时间',
  clearCaches: '清空店铺缓存',
  clearCachesAll: '清空店铺缓存（全部缓存）',
  clearCaches1: '清空缓存',
  share: '分享选中店铺与他人共用',
  transform: '转移选中店铺给他人',
  transformDevice: '同时转移绑定的IP设备',
  removeShared: '删除选中分享店铺',
  deleteBrowsers: '删除选中店铺',
  deleteBrowsers1: '删除该店铺',
  deleteCompletely: '彻底删除选中店铺',
  deleteCompletely1: '彻底删除该店铺',
  deleteCompletely2: '彻底删除店铺',
  reopenAllAtPos: '批量重启所有店铺',
  moveToRecycle: '删除选中店铺至回收站',
  moveToRecycle1: '删除该店铺至回收站',
  belongTo: '归属：',
  noProxy: '直连模式',
  remark: '备注',
  lastOpenTime: '最近打开时间：',
  lastCloseTime: '最近关闭时间：',
  lastUpdateTime: '修改时间：',
  updateBy: '修改用户：',
  userName: '用户名：',
  isOpening: '正在打开该店铺！',
  browserDisabled: '可用店铺数减少，店铺自动失效',
  opening: '打开中',
  open: '打开',
  goDown: '跳到底端',
  goUp: '跳到顶端',
  goMiddle: '跳到中间',

  editGroup: '修改分组',
  editGroupWithSeq: '修改分组（序号：{seq}）',
  editNameWithSeq: '修改店铺名称（序号：{seq}）',
  browserName: '店铺名称',
  addBorwserTip1: '店铺名称的唯一校验，用于页面展示。',

  editRemark: '修改备注',
  editRemarkWithSeq: '修改备注（序号：{seq}）',
  editRemarkType: '修改方式',
  replaceRemark: '替换',
  appendRemark: '追加',
  appendRemarkTips: '注：使用“追加”，则在原有备注基础上，换行去追加新内容',
  remarkContent: '备注内容',
  exportFromSeq: '起始序号',
  minSeqPlaceholder: '请输入大于0的数字',
  exportToSeq: '结束序号',
  maxSeqPlaceholder: '请输入大于起始序号的数字',
  shareTitle: '分享店铺',
  shareTips: '可以分享店铺给多个用户共同使用，输入用户名后点击“添加”按钮即可。',
  shareWith: '分享给用户',
  shareAdd: '添加',
  shareRemark: '分享备注',
  transformTitle: '转移店铺',
  transformTips: '提示：转移店铺后，店铺将完全归属于被转移公司，您将不可再看到该店铺，请谨慎操作！',
  打开店铺发生异常:
    '打开店铺发生异常：1. 可能系统时间不准确造成，请调整为准确时间； 2. 浏览器可能被杀软删掉，请退出杀软后重新安装；3. 64位系统不兼容，下载32位客户端后重试；4. Mac版支持10.15 Catalina及以上；5. 以上方法尝试后问题还未解决，请联系客服！',

  transformTo: '转移给公司',
  groupName: '分组名称',
  checkAll: '勾选全部',
  loading: '加载中...',
  checkGroup: '请选择分组',
  ruleNameMsg: '店铺名称不能超过50个字符',
  ruleRemarkMsg: '备注不能超过500个字符',
  ruleMinSeqMsg: '起始序号大于0',
  ruleMaxSeqMsg: '结束序号大于起始序号',
  usernameMinLength: '用户名最少6位',
  usernameExists: '已添加此用户名',
  remarkMaxLength: '备注最多100个字',
  transformGroupMsg: '请输入转移给用户名下的分组名称',
  noAddPermission: '您当前无创建店铺权限，请联系账号管理员开通',
  setSuccess: '设置成功',
  notInClient: '请安装客户端软件，在客户端软件中打开店铺！',
  installText: '安装客户端',
  getExtensionFailed: '获取扩展出错，请检查网络',
  getConfigFailed: '获取配置失败，请尝试刷新重试',
  openAnyway:
    '浏览器店铺已在其它账号打开，是否要在本账号下重复打开？<p style="margin-top: 10px">注意：重复打开后，不同账号关闭店铺后都会触发同步操作，可能造成各自同步不同的数据！</p>',
  cloneMsg: '克隆店铺，只允许选择一个店铺进行克隆，禁止多选！',
  openAnyway2: '该浏览器店铺在其他账号下已被同步为关闭状态，是否在本账号下重新打开？',
  selectBrowser: '请选择店铺',
  noBrowserChecked: '未选择店铺',
  freeAccountTips: '该功能，免费套餐会有一定限制，收费套餐支持无限制使用。如需无限制使用，请开通/续费收费套餐后即可使用！',
  close: '关闭',
  checkedIncludesOpened: '所选店铺中包含已打开的店铺，请先关闭店铺后再进行操作',
  editShareGroupMsg: '分享的店铺不可修改分组',
  editNameSuccess: '店铺名称修改成功',
  editRemarkSuccess: '备注修改成功',
  deleteConfirmMsg: '确定删除所选{arg1}个店铺吗？<br/>删除后可在店铺回收站中恢复！',
  deleteConfirmMsg1: '确定删除 {arg1} 店铺吗？<br/>删除后可在店铺回收站中恢复！',
  moveToRecycleSuccess: '店铺回收成功',
  deleteCompletelyConfirm: '确定彻底删除 {arg1} 店铺吗？<p class="text-red">店铺彻底删除后不可恢复，请谨慎操作！</p>',
  deleteCompletelyConfirm2: '确定彻底删除全部店铺吗？<p class="text-red">店铺彻底删除后不可恢复，请谨慎操作！</p>',
  deleteCompletelyConfirm1: '确定彻底删除所选{arg1}个店铺吗？<p class="text-red">店铺彻底删除后不可恢复，请谨慎操作！</p>',
  deleteCheckPwd: `<p class="text-bold q-mb-sm">如需关闭密码验证，请到<u class="cursor-pointer">个人中心</u>关闭！</p>`,
  deleteCompletelySuccess: '删除成功',
  deleteShareConfirm: '确认删除分享店铺吗？',
  deleteShareSuccess: '删除分享成功',
  handleInClient: '请在客户端中操作',
  clearCachesConfirm: '清空浏览器缓存会导致浏览器相关登录状态等全部失效。<br/>确定要清空吗？',
  clearCacheSuccess: '删除成功',
  checkedHasClosed: '所选店铺已关闭',
  allBrowserClosed: '已关闭所有店铺',
  transformSuccess: '店铺转移成功',
  atleatShareOneUser: '至少填写并添加一个用户',
  shareSuccess: '分享成功',
  exportConfirm: '确定要导出选中项吗？',
  exportFilename: '导出店铺.xlsx',
  otherAccountTel: '导入附加账号模板.xlsx',
  importShopTip: '也支持导入市面常见电商浏览器的导入文件',
  browserHasOpened: ' 店铺已打开',
  exportFilenameWithSeq: '导出店铺{seq}.xlsx',
  batchOpenTitle: '批量打开店铺',
  batchOpenChecked: '已选店铺：',
  batchOpened: '已打开：',
  batchOpenFailed: '打开失败：',
  toSetting: '点击前往【系统设置】，关闭或修改该动作的触发条件',
  batchOpening: '正在打开...',
  batchOpenTips: '注意：弹窗关闭后，未打开店铺将不再继续打开',
  CPU使用率: 'CPU使用率',
  内存使用率: '内存使用率',
  缓存所在目录剩余空间小于: '缓存所在目录剩余空间小于',
  extractIpFailed: '代理IP提取失败，停止打开',
  batchUpdateDialog: '批量更新店铺信息',
  batchUpdateImport: '单个excel文件，导入店铺个数不要大于1000个',
  batchUpdateComment: '操作说明：',
  batchUpdateComments: '1. 首先导出需要更新信息的店铺 | 2. 根据导出文件中的ID，修改对应的店铺信息 | 3. 将修改后的Excel文件上传至服务器',
  batchUpdateTips: '注意：不要删除或修改Excel文件中的ID，会导致识别不出店铺信息，不想更新的数据，请勿改动或删除',
  proxyIp: '代理IP',
  createdTime: '创建时间',
  pleaseCreatedTime: '请选择创建日期',
  config: '配置',
  fileImport: '文件导入',
  importExcel: '导入Excel',
  downloadTemplate: '下载导入模板',
  importTips: '*也支持导入市面常见指纹浏览器的导入文件',
  downTemplateTips: '温馨提示',
  downTemplateTips2: '导入模板已更新，请下载最新模板使用！',
  checkFileErrMsg: '请选择文件',
  downloadFilename: '导入店铺模板.xlsx',
  uploadMaxSize: '上传文件大小不能超过20MB',
  openProgressDialog: '店铺正在打开...',
  browserOpened: '店铺已打开',
  searchItem: '搜索项目',
  setDefault: '设默认',
  searchNamePlaceholder: '输入店铺名搜索',
  searchUsernamePlaceholder: '输入用户名搜索',
  searchSeqPlaceholder: '按指定序号搜索（多个序号使用逗号分隔）',
  setSeq: '指定序号',
  minSeq: '最小序号',
  maxSeq: '最大序号',
  searchRemarkPlaceholder: '输入备注搜索',
  searchRemark: '店铺备注',
  searchProxyPlaceholder: '选择代理类型搜索',
  searchHostPlaceholder: '输入代理主机搜索',
  searchBrowserStatus: '店铺打开状态',
  seqRuleMsg: '请输入0 - 999999的数字',
  seqSplitRuleMsg: '请输入英文逗号分隔的整数序号',
  proxyRuleMsg: '请选择合法的代理类型',
  hostRuleMsg: '代理主机最多100个字符',
  ipRuleMsg: 'IP地址最多50个字符',
  shareBtn: '分享管理',
  shareDialog: '我的分享管理',
  shareBrowserNamePlaceholder: '输入店铺名称搜索',
  shareRemarkPlaceholder: '输入备注搜索',
  shareUsernamePlaceholder: '输入分享用户名搜索',
  shareHandlerUser: '操作用户',
  shareCancel: '取消分享',
  shareCancelTips: '您确定要取消此店铺的分享吗？<br/>取消分享后，被分享的用户将对此店铺不再可见。',
  shareCancelSuccess: '取消分享成功',
  检查并同步扩展中: '检查并同步扩展中...',
  '扩展同步失败，请尝试重新启动': '扩展同步失败，请尝试重新启动...',
  '可能因为网络不稳造成，请尝试在客户端的左上角切换线路后重试！': '可能因为网络不稳造成，请尝试在客户端的左上角切换线路后重试！',
  开始获取IP: '开始获取IP...',
  '直连模式，网络畅通': '直连模式，网络畅通...',
  IP代理成功: 'IP代理成功...',
  网络不通已停止打开浏览器: '网络不通已停止打开浏览器...',
  正在同步指纹信息: '正在同步指纹信息...',
  正在同步缓存配置: '正在同步缓存配置...',
  指纹同步失败: '指纹同步失败',
  '浏览器缓存文件创建失败，请重试': '浏览器缓存文件创建失败，请重试',
  正在打开浏览器窗口: '正在打开浏览器店铺...',
  店铺已打开: '店铺已打开',
  '正在更新浏览器内核，请稍后': '正在更新浏览器内核，请稍后...',
  打开店铺发生异常:
    '打开店铺发生异常：1. 可能系统时间不准确造成，请调整为准确时间； 2. 浏览器可能被杀软删掉，请退出杀软后重新安装；3. 64位系统不兼容，下载32位客户端后重试；4. Mac版支持10.15 Catalina及以上；5. 以上方法尝试后问题还未解决，请联系客服！',
  缓存所在目录剩余空间小于: '店铺 {arg1} 启动失败，缓存所在目录剩余空间小于 {arg2}',
  内存使用率超出: '店铺 {arg1} 启动失败，内存使用率超出 {arg2}',
  同步IndexedDB失败: '同步IndexedDB失败',
  '同步Local Storage失败': '同步Local Storage失败',
  同步书签失败: '同步书签失败',
  同步历史记录失败: '同步历史记录失败',
  同步已保存的密码失败: '同步已保存的密码失败',
  同步Google账号信息失败: '同步Google账号信息失败',
  代理IP无法连接: '代理IP无法连接',
  exportCookies: '批量导出Cookie',
  exportSelectedCookies: '批量导出店铺Cookie',
  exportCookiesBySeq: '导出指定序号范围店铺的Cookie',
  exportCookiesByGroup: '导出指定分组店铺的Cookie',
  exportCookiesConfirm: '确认以文本方式导出选中店铺的Cookie吗？',
  importCookies: '批量更新店铺Cookie',
  importCookiesTips: '注意：',
  importCookiesTips1: '1. 每个店铺Cookie必须单独使用一个txt文件，以店铺名命名。比如店铺名为：123，则命名为：123.txt；',
  importCookiesTips2: '2. 所有txt文件放至同一个文件夹下，文件夹命名为：Cookies，然后压缩为zip文件；',
  importCookiesTips3: '3. 一次最多更新100个txt格式（店铺）的Cookie，zip文件大小不能超过20MB；',
  importCookiesTips4: '4. 上传成功后，对应序号店铺的Cookie将会替换更新为您上传的最新Cookie。',
  notZip: '请选择 Zip 文件上传。',
  importCookiesUploader: 'ZIP压缩包中包含txt文件不超过100个',
  updateCookiesSuccess: 'Cookie更新成功',
  Recently: '最近打开',
  renewal: '急需续费',
  needBinding: '待绑定',
  needAuthorized: '待授权',
  buyEquipment: '购买IP设备',
  buyOwnDevice: '添加自有IP',
  shop: '店铺',
  importAttachment: '批量导入附加账号',
  additionalManagement: '附加账号管理',
  device: '设备',
  assignDevices: '绑定/解绑店铺设备',
  bindDevice: '绑定IP设备',
  bindDevice1: '绑定选中店铺设备',
  unbindDevice: '解绑设备',
  unbindDevice1: '解绑选中店铺设备',
  authorizeSelect: '授权选中店铺',
  authorizedEmployees: '授权员工',
  morePlatforms: '更多平台',
  bindingSuccessful: '绑定成功',
  unbindDeviceTip: '当前所选店铺数量{arg1}个',
  unbindDeviceTip1: '确定解绑所选店铺的设备?',
  unbindSuccess: '解绑成功',
  authorizationSuccess: '店铺授权成功',
  pleaseBindDevice: '请绑定IP设备',
  pleaseSelectSiteName: '请选择平台名称',
  pleaseSelectSite: '请选择平台站点',
  pleaseSelectSite2: '请选择站点',
  pleaseSelectCustomSite: '请添加或选择自定义平台',
  platformName: '平台名称',
  siteUrl: ' 平台站点',
  toChoose: '去选择',
  PleaseShopName: '请输入店铺名称',
  PleaseShopMarke: '请输入店铺备注',
  isBindDevice: '是否绑定设备',
  yes: '是',
  no: '否',
  pleaseSelectDevice: '请选择绑定设备',
  equipmentArea: '设备地区',
  pleaseEquipmentArea: '请选择设备地区',
  isAuthorized: '是否授权',
  pleaseSelectEmployee: '请选择授权员工',
  SelectEmployee: '选择授权员工',
  loginAccount: '登录账号',
  pleaseSelectEmployee1: '选择员工',
  sharingRestrictions: '共用限制',
  accountTip: '（选择限制，则该店铺同一时间只允许一个账户打开）',
  accountTip1: '账号保护（勾选后自动填入网站的账号以及密码，不可修改）',
  accountTip2: '账号开启了账号保护，不可修改，仅支持登录当前下拉账号的账号，如需修改，请联系管理员',
  simpleMode: '简单模式',
  expertMode: '专家模式',
  advancedSetting: '高级设置',
  limitation: '限制',
  noLimit: '不限制',
  所有平台: '所有平台',
  电商后台: '电商后台',
  电商前台: '电商前台',
  独立站: '独立站',
  支付平台: '支付平台',
  邮箱: '邮箱',
  其他: '其他',
  自定义: '自定义',
  details: '店铺详情',
  deviceDetails: 'IP设备详情',
  lastLoginStatus: '最后登录情况',
  notSet: '未设置',
  pleaseAccountName: '请输入账号名称',
  addNewAccount: '',
  addExistingAccount: '添加已有账号',
  AddAccount: '添加新账号',
  currentStore: '当前店铺：',
  addAccountTip:
    '绑定的附加账号会展示在主店铺的工作台页面，附加账号中的账号、密码等信息会自动填充至附加账号的登录框，便于快捷打开；建议添加邮箱、支付平台等账号！',
  editAccount: '编辑附加帐户',
  addAdditionalAccount: '添加附加账号',
  accountName: '账号名称',
  accountDetails: '账号详情',
  accountNameLimit: '账号名称不能大于50字符',
  pleaseAddAccount: '请选择添加账号',
  addAccountTitle: '主店铺-添加已有附加账号',
  batchImportExc: '批量导入店铺',
  updateDate: '更新店铺数据',
  batchImportAccount: '批量导入附加账号',
  importTips1: '*导入须知',
  ImportTips2: '仅支持后缀名为xls、xlsx、csv的Excel文件，文件大小限10M以内，每次导入条数不超过300条。',
  importTips3: '批量导入的附加账号默认授权有主店铺权限的员工；解除主店铺与附加账号的关联，可在[附加账号管理] 中操作。',
  download: '下载',
  downLoadTip2: '在此数据基础上，按照表格要求进行修改后再上传！',
  batchUpdateTips1: '请先选择的要更新的店铺数据，在此数据基础上进行修改后再进行上传。',
  updateTel: '选中的店铺数据.xlsx',
  pleaseCompanyName: '请输入公司名',
  userNameMaxLength: ' 账号最多100字符',
  numTime: `{arg1}个小时`,
  cookieMax: '最高不可超出720小时',
  account: '账号',
  syncCookieStore: '按店铺同步',
  syncCookieStore1: '多个用户使用同一个店铺Cookie',
  syncCookieUser: '按用户同步',
  syncCookieUser1: '多个用户使用多个不同的店铺Cookie',
  syncCookie: '不同步',
  syncCookie1: '不同步店铺Cookie，以新的状态打开',
  cookieMethod: 'Cookie方式',
  accountProtection: '账号保护',
  detailedRequest: '详细信息',
  operationalInformation: '操作信息',
  accessLog: '访问日志',
  requestingAuthorization: '请求授权原因',
  submit: '提交申请',
  submitTip: '提交后请联系上级为您授权',
  submitTip2: '在新终端登录',
  submitTip3: '您当前登录的账号需要上级授权才能登录',
  operator: '操作人',
  pleaseSelectUser: '请选择被交接员工',
  unbindShop: '确定要解绑 {arg1} 店铺的设备吗?',
  reopening: '正在重启店铺',
  reopenFinished: '店铺重启完成',
  reopeningAndWait: '店铺正在重启中，请耐心等待...',
  reopenErrors: '以下店铺未能成功重启：',
  shopError: '无效店铺',
  deviceError: '设备无效',
  storeUnauthorized: '店铺未授权',
  shopNot: '店铺不存在',
  自定义平台: '自定义平台',
  storeImport: '店铺导入',
  bindeviceTip: '当前选择的设备已经绑定过{arg1}个店铺，可能会触发店铺IP关联风控，为了店铺安全不建议这样操作！',
  bindeviceTip1: '当前选择的设备已经绑定过{arg1}个店铺，多个店铺同时使用同一设备，可能导致店铺关联风险且网速较差！',
  bindeviceTip2: '您选择了{arg1}个店铺绑定该设备，多个店铺同时使用同一设备，可能导致店铺关联风险且网速较差！',
  confirmBinding: '确认绑定',
  rebind: '重新绑定',
  rebindtip: 'IP设备绑定店铺前，请了解各平台站点的政策。',
  rebindtip1: '多个店铺同时使用同一[IP设备]，可能导致店铺关联风险且网速较差，为了店铺安全不建议这样操作！',
  rebindtip2: '若列表中没有合适的IP设备，请您：',
  rebindCrossTip: '提示：一个跨境专线只能绑定一个海外的平台IP设备，大陆的平台IP设备和自有IP设备无法绑定！',
  notAdded: '未添加',
  expriceDevice: '当前设备已过期，无法打开店铺，请续费后重试！',
  selectStoresTip: '已选择{arg1}个店铺',
  currentDevice: '当前设备：',
  cloneSucces: '克隆成功',
  transformRule: '公司名称最多100个字符',
  labelManagement: '标签管理',
  setLabel: '设置标签',
  batchsetLabel: '批量设置标签',
  customLabels: '自定义标签',
  tag: '标签',
  enterLabelName: '请输入标签名称',
  labelNameRules: '标签名不能超过20个字符',
  addTagSuccess: '标签添加成功',
  tagName: '标签名称',
  deleteTag: '删除标签',
  editTag: '编辑标签',
  toBrowser: '点击快速查看标签店铺',
  setTagTip: '标签设置成功',
  selectTag: '选择标签',
  newTag: '添加新标签',
  addTag: '添加标签',
  noTag: '无标签',
  platformClassify: '平台分类',
  selectTagTip: '请选择标签',
  batchDel: '批量删除',
  deletetags: '请选择需要删除的标签',
  tagEditSuccess: '标签修改成功',
  tagDeleteSuccess: '标签删除成功',
  storeNum: '店铺数量',
  transformTip: '注意：如果被转移的IP设备同时绑定了其他店铺，则转移后其他店铺变为IP设备解绑状态，需要重新绑定才可正常打开！',
  transformTip2: '如果被转移的IP设备同时绑定了跨境专线，不会一同转移！',
  clearCacheWithoutExtensions: '清空店铺缓存（保留扩展数据）',
  clearCachesConfirm2: '此操作会清空除了店铺扩展及扩展数据外的所有缓存文件，确定清空吗？',
  openBrowserError:
    '打开店铺发生异常：1.请检查系统时间是否准确，如若系统时间不准确，请调整为准确时间； 2.可能软件客户端不完整造成，尝试卸载软件，然后下载并安装最新版后再试； 3.可能系统缺少某些组件、不完整造成，如：Ghost系统、简易系统，请更新操作系统及更新驱动，或安装完整版操作系统后再试。 4.若以上方法尝试后问题还未解决，请联系客服',
  waitBatchOpenBrowser: '正在批量打开店铺，请稍等...',
  beginning: '开头',
  contain: '包含',
  customSort: '自定义排序',
  editCustomSort: '修改排序',
  sortLabel: '排序',
  customSortBtn: '一键随机自定义排序',
  customSortDialogTip1: '请先到“自定义显示列”中，勾选“自定义排序”，才可以使用该功能。',
  customSortDialogTip2: '{msg}会覆盖原有的排序值，并无法找回，是否要继续修改？',
  sortOkBtn: '去设置',
  sortRuleMsg2: '排序数字必须大于等于0且小于3000000',
  openToolTip: '用户名：{userName}正在打开该店铺！',
  updateSortOfChecked: '修改选中店铺自定义序号',
  customSeqMsg: '随机自定义排序',
  positiveSequenceNum: '按正序生成序号',
  reverseSequenceNum: '按倒序生成序号',
  startSerialNumlabel: '起始序号',
  sequenceNumplaceholder: '请输入序号',
  positiveSequenceTip: '所选的窗口按设置的起始序号正序依次生成序号, 会覆盖原有的排序值，并无法找回！',
  reverseSequenceTip: '所选的窗口按设置的起始序号倒序依次生成序号, 会覆盖原有的排序值，并无法找回！',
  authorizeLogin1: '登录新终端设备需要授权',
  authorizeLogin2: '未在登录时间内',
  authorizeLogin3: '登录新终端设备需要授权, 且未在登录时间内',
  openMethod: '店铺打开方式',
  manualOpening: '手动打开',
  AutomaticallyOpen: '自动打开当前店铺',
  AutomaticallyOpenHistroy: '自动打开上次浏览的网页',
  unbindStoreTip1: '确定解绑所选的{arg}个IP设备中已绑定的所有店铺吗？',
  unbindStoreTip2: '解绑后，IP设备可以再次进行绑定店铺！',
  ipAddress: 'IP设备地址',
  ipAddressSearch: '请输入IP设备地址'
}
