export default {
  base: 'Basic',
  preference: 'Preference',
  fingerprint: 'Fingerprint Preference',
  global: 'Global',
  openAtLogin: 'Open At Login',
  openAtLoginTips: '若加入开机启动无效，请下载最新版软件并重新安装',
  download: 'Download',
  cacheDir: 'Browser Cache Dir',
  changeCacheDir: 'Change Dir',
  clearCache: 'Clear Cache',
  cacheClearRule: 'Please enter an integer number greater than 1.',
  autoDeleteCache: 'Automatically Delete Cache(days)',
  days: 'Days',
  daysPlaceholder: 'Please enter the number of days',
  autoDeleteTips: 'The cache before this number of days will be deleted automatically. Leave blank if you dont want to delte.',
  detectAndDelete: 'Detect And Delete',
  autoPasteDelay: 'Auto Paste Delay',
  autoPastePlaceholder: 'Please enter the number of milliseconds',
  autoPasteTips1: 'Note: The value entered here is ms, 1000ms = 1s',
  autoPasteTips2:
    'The input delay of "Auto Paste". The greater the value, the greater the delay, and the longer it takes to type. It is recommended that the delay should not exceed 500ms!',
  autoPasteTips3:
    'Instructions: Press the shortcut keys Ctrl+Shift+E in the input box to input the contents of the clipboard one by one at the delay speed you set.',
  milliseconds: 'ms',
  batchOpenInterval: 'Batch Open Browser Interval',
  batchOpenPlaceholder: 'Please enter the number of milliseconds',
  batchOpenTips: 'When multiple browsers are opened in batch, the interval between pauses will be longer if the value is larger.',
  batchOpenRule: 'Please enter an integer number of milliseconds greater than 0',
  badge: 'Custom Badge',
  badgeEmpty: 'Default Icon',
  badgeBySeq: 'Browser Serial No.',
  badgeByName: 'Browser Name(First 3 characters)',
  badgeByRemark: 'Browser Remarks(First 3 characters)',
  badgeById: 'Browser ID(Last 5 characters)',
  performance: 'Performance',
  performanceTips:
    'Enable to check the computer performance before open browser, and the browser will be stopped when the computer performance is insufficient.',
  performanceValue: 'Limit Value',
  minMemory: 'Memory Greater Than',
  minDiskSpace: 'Free Space of Cache Located Is Less Than',
  performanceTips2: 'The browsers will stop opening while the performance monitoring triggers the limit value.',
  localApi: 'Local Api',
  logs: 'Logs',
  openDir: 'Open Dir',
  netDetect: 'Network Detection',
  detect: 'Detect',
  saveBase: 'Save Basic Infos',
  lumautoTitle: 'Luminati Configuration',
  oxylabsautoTitle: 'Oxylabs Configuration',
  iphtmlautoTitle: 'IPHTML Configuration',
  ipideaautoTitle: 'IPIDEA Configuration',
  ip2worldTitle: 'IP2WORLD Configuration',
  lunaproxyTitle: 'Lunaproxy Configuration',
  bookmarksTitle: 'Import Chrome Bookmarks',
  freeSpaceRule1: 'Must be greater than or equal to 1',
  freeSpaceRule2: 'Up to two decimal places',
  sureToLeave: 'The configuration of fingerprint preference is not saved yet, confirm to leave?',
  changeDirConfirm:
    'Changing the cache directory will not automatically copy the cache file to the new directory. Please copy it manually if necessary.<br/><br/>Current dir: {dir}',
  clearCacheConfirm: 'This will clear all browsers cache files(Including browser cache files under other accounts).',
  clearCacheSuccess: 'Cleared all browsers caches.',
  saveFailed: 'Save failed, please relaunch app and try again.',
  upgradeTips: 'The latest version of the client is available. Please upgrade the client',
  bookmarksTips: 'Note: Select a bookmark file to automatically get the bookmarks, and all browser windows will import the bookmarks;',
  bookmarksLabel: 'Html Bookmark File Exported From Chrome',
  bookmarksBar: 'Bookmark Bar',
  noBookmarks: 'No Bookmarks',
  otherBookmarks: 'Other',
  bookmarksClearConfirm: 'Confirm to clear Bookmarks?',
  autoComplete: 'Autofilled Username And Passwords',
  autoCompleteTips: 'Note: This function will automatically fill in the user name and password for all your browsers globally!',
  editAutoComplete: 'Update Autofilled Password',
  addAutoComplete: 'Add Autofilled Password',
  autoCompleteUrl: 'Domain',
  autoCompleteUsername: 'Username',
  badgeBySort: 'Browser Sorting index (last 5 digits)',
  autoCompletePassword: 'Password',
  urlRule1: 'Please enter a valid url',
  urlRule2: 'Please enter a valid url',
  usernameRule: 'Please enter username',
  passwordRule: 'Please enter password',
  deleteAutoCompleteConfirm: 'Confirm to delete this record?',
  duplicatedAutoComplete: 'Already added same info.',
  add: 'Add',
  ipWhitelist: 'Proxy IP Blacklist',
  ipWhitelistTips:
    'Tip: After adding the domain name to the proxy IP blacklist, the corresponding domain will be directly connected without the Proxy IP. The domain name supports wildcards, such as *.amazon.com.',
  domainPlaceholder: 'Please enter a domin without protocol, for example: google.com',
  domain: 'Domain',
  ipBtns: 'Quick Setting(Save proxy traffic)',
  fbBtn: 'Use local network to load Amazon static resources while opening.',
  domainRequired: 'Domain is required.',
  noProtocol: 'No need to add "http | https" prefix.',
  domainRepeat: 'Already added this domain.',
  saveAndDownDir: 'File Save And Download Directory',
  saveAndDownDirTips:
    'After setting, the file save and download directory of all browsers will automatically use the corresponding seq number folder under this directory.',
  selectDir: 'Select Dir',
  selectFileDir: 'File Selection And Upload Directory',
  selectFileDirTips: 'After setting, this directory will be used by default when selecting and uploading files.',
  pageZoom: 'Web Page Zoom',
  pageZoomTips: 'Set the webpage zoom ratio for all browsers globally.',
  extensionSwitch: 'Show "Sync Extension Data Across Browsers"',
  extensionSwitchTips:
    'After turning it on, after turning on the "Sync Extension Data Across Browsers" switch in the "Preference" item of the browser profile, this switch item will be displayed.',
  closeSoftware: 'Close Software',
  rememberChoose: 'Remember Choose',
  toTrayAtLaunch: 'Minimize To Tray After Opening',
  brwoserOpenForm: 'Stores Start Style',
  brwoserOpenFormTip: 'All stores will open with the configured style.',
  defaultPosition: 'Default Position',
  minimize: 'Start Minimize',
  maximize: 'Start Maximize',
  enableGoogleTranslate: 'Google Translate Assistant',
  enableGoogleTranslateTip: 'When your proxy IP location is a city in China, you can still use Google Translate.',
  bookmarksMode: 'Bookmark Import Mode',
  mergeMode: 'Merge',
  replaceMode: 'Replace',
  mergeTips:
    'Open windows will merge and display both existing bookmarks and globally imported bookmarks; for example, if there are existing bookmarks A and globally imported bookmarks B, the open window will display both bookmarks A and B simultaneously.',
  replaceTips:
    'The open window will replace the existing bookmarks and display only the globally imported bookmarks; for example, if the window has existing bookmark A and globally set bookmark B, the open window will display only the globally set bookmark B.',
  sysProxy: 'System Proxy Configuration',
  sysProxyTips: '',
  sysProxyServer: 'Proxy Server',
  sysProxyOpt1: 'Auto Detect System Proxy Configuration',
  sysProxyOpt2: 'Do Not Use System Proxy Configuration',
  sysProxyOpt3: 'Customize System Proxy Port',
  sysProxyPort: 'Custom Port',
  positionTips: 'Default top-left corner (x: 0, y: 0).',
  detectSuccess: 'Detect Success: ',
  detectFailed: 'Detect Failed',
  processProtect: 'Process Protection',
  processProtectTips:
    'Note: When running the script, if residual processes are found, you can configure the following options to clean up the residual processes regularly. 0 to disable this feature.',
  processProtectPeriod: 'Interval (minutes)',
  addFolder: 'Add new folder',
  addBookmark: 'Add new bookmark',
  pleaseName: 'Please enter name',
  bookmarkExists: 'Name already exists',
  urlExists: 'URL already exists',
  isDeleteBookeTip: 'The bookmark is about to be deleted. Do you want to continue?',
  folder: 'Folder',
  bookmark: 'Bookmark',
  delete: 'Delete',
  name: 'Name',
  pressShortcut: 'Please press the shortcut key.',
  multiKeys: 'The shortcut must be a combination of a function key and a letter key.',
  shortcutKeysStart: 'The shortcut key must start with {keys}.',
  noBothCtrlAndAlt: 'The shortcut key cannot contain both Ctrl and Alt/Option keys simultaneously.',
  setShortcutSuccess: 'Shortcut key set successfully!',
  setShortcutFailed: 'Failed to set the shortcut key, please refresh and try again!',
  shortcutKeys: 'Auto Paste Shortcut Keys',
  promptForDownload: 'Ask Where To Save Each File Before Downloading'
}
